// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { sanitizePathName } from "./paths.ts";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { isPresent } from "../utils/checks.ts";

/* eslint-disable import/no-unused-modules */
export const PageABTestCookieName = "ssc-ab-test-page-experiments";
export const ComponentABTestCookieName = "ssc-ab-test-component-experiments";

type SegmentURL = string;

export type ABTestExperimentBase = {
  id: string;
  version: number;
  weighting: number;
};
export type ABTestExperimentPages = ABTestExperimentBase & {
  _type: "page";
  urls: { aURL: SegmentURL; bURL: SegmentURL }[];
  iso2Countries?: string[];
};

export type SpecificABTestExperimentPage = ABTestExperimentBase & {
  _type: "page";
  aURL: SegmentURL;
  bURL: SegmentURL;
  iso2Countries?: string[];
};

export type ABTestExperimentComponent = ABTestExperimentBase & {
  _type: "component";
};
export type ABTestExperiment =
  | ABTestExperimentPages
  | ABTestExperimentComponent;

type ActiveExperiments = typeof activeExperiments;
export type ExperimentId =
  | ActiveExperiments[number]["id"]
  | (typeof temporaryDebugExperiments)[number];

const temporaryDebugExperiments = [] as const;

export const activeExperiments = [
  // {
  //   _type: "component" as const,
  //   id: "remove-ctas-cruise-page",
  //   version: 1,
  //   weighting: 0.5
  // }
  {
    _type: "component" as const,
    id: "fyc-cruise-drawer",
    version: 1,
    weighting: 0.5
  },
  {
    _type: "component" as const,
    id: "free-cancellation-price-label",
    version: 1,
    weighting: 0.5
  }
  // {
  //   _type: "page" as const,
  //   id: "inbound-v2",
  //   version: 1,
  //   weighting: 0.5,
  //   urls: [
  //     {
  //       aURL: "/lp-leader-in-luxury-cruising-all-australia-1.html",
  //       bURL: "/lp-leader-in-luxury-cruising-all-australia-1-ab.html"
  //     },
  //     {
  //       aURL: "/lp-leader-in-luxury-cruising-asia-1.html",
  //       bURL: "/lp-leader-in-luxury-cruising-asia-1-ab.html"
  //     },
  //     {
  //       aURL: "/lp-leader-in-luxury-cruising-antarctica-1.html",
  //       bURL: "/lp-leader-in-luxury-cruising-antarctica-1-ab.html"
  //     },
  //     {
  //       aURL: "/lp-leader-in-luxury-cruising-galapagos-1.html",
  //       bURL: "/lp-leader-in-luxury-cruising-galapagos-1-ab.html"
  //     },
  //     {
  //       aURL: "/lp-leader-in-luxury-cruising-mediterranean-1.html",
  //       bURL: "/lp-leader-in-luxury-cruising-mediterranean-1-ab.html"
  //     },
  //     {
  //       aURL: "/lp-leader-in-luxury-cruising-northern-europe-1.html",
  //       bURL: "/lp-leader-in-luxury-cruising-northern-europe-1-ab.html"
  //     },
  //     {
  //       aURL: "/lp-leader-in-luxury-cruising-alaska-1.html",
  //       bURL: "/lp-leader-in-luxury-cruising-alaska-1-ab.html"
  //     },
  //     {
  //       aURL: "/lp-leader-in-luxury-cruising-french-polynesia-and-pacific-1.html",
  //       bURL: "/lp-leader-in-luxury-cruising-french-polynesia-and-pacific-1-ab.html"
  //     },
  //     {
  //       aURL: "/landing-why-silversea-4-1.html",
  //       bURL: "/landing-why-silversea-4-1-ab.html"
  //     },
  //     {
  //       aURL: "/lp-world-cruise-2026-n.html",
  //       bURL: "/lp-world-cruise-2026-n-b.html"
  //     }
  //   ],
  //   iso2Countries: ["US"]
  // }
] as const satisfies ABTestExperiment[];

export type SegmentId = "a" | "b";
export type ExperimentTag = {
  experimentId: string;
  segmentId: SegmentId;
};

export const experimentFromUrl = (
  path: string,
  experiments: readonly ABTestExperiment[]
): SpecificABTestExperimentPage | undefined => {
  return onlyABTestExperimentPages(experiments)
    .map((e) => {
      const matchingUrl = e.urls.find(aOrBUrlMatching(path));
      if (!matchingUrl) return undefined;

      return {
        _type: "page" as const,
        id: e.id,
        version: e.version,
        weighting: e.weighting,
        iso2Countries: e.iso2Countries,
        aURL: matchingUrl.aURL,
        bURL: matchingUrl.bURL
      };
    })
    .filter(isPresent)[0];
};

export const segmentFromUrl = (
  experiment: SpecificABTestExperimentPage,
  path: string
): SegmentId | undefined => {
  const sanitizedPath = sanitizePathName(path);
  if (sanitizedPath == experiment.aURL) return "a";
  if (sanitizedPath == experiment.bURL) return "b";
  return undefined;
};

export const urlForSegment = (
  experiment: SpecificABTestExperimentPage,
  segment: SegmentId
): SegmentURL => (segment == "b" ? experiment.bURL : experiment.aURL);

export const urlMatch = (
  url: string,
  e: ABTestExperiment
): e is ABTestExperimentPages =>
  e._type == "page" && e.urls.some(aOrBUrlMatching(url));

const aOrBUrlMatching =
  (path: string) =>
  (u: { aURL: SegmentURL; bURL: SegmentURL }): boolean =>
    u.aURL === sanitizePathName(path) || u.bURL === sanitizePathName(path);

export const urlMatchASegment = (
  url: string,
  e: ABTestExperimentPages
): boolean => {
  return e.urls.some((u) => u.aURL === url);
};

export const onlyABTestExperimentPages = (
  experiments: readonly ABTestExperiment[]
): ABTestExperimentPages[] =>
  experiments.filter((e): e is ABTestExperimentPages => e._type == "page");

export const onlyABTestExperimentComponents = (
  experiments: readonly ABTestExperiment[]
): ABTestExperimentComponent[] =>
  experiments.filter(
    (e): e is ABTestExperimentComponent => e._type == "component"
  );
